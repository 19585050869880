import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import Image from '../../../src/Components/Image';
import {Context} from '../../../src/AppContext';
const config = require('../../../src/data.json');

function getProductTagCss(tag) {
  if (tag === 'HOT') {
    return 'background-color: #EB5554;';
  } else if (tag === 'SALE') {
    return 'background-color: #C89664;';
  } else if (tag === 'NEW') {
    return 'background-color: #8AC262;';
  }
  return '';
}

export default function ProductItem(props) {
  const app = useContext(Context);
  const [freeShipping, setFreeShip] = useState(false);
  const {product, extraCss = '', onClick} = props;

  useEffect(() => {
    if (product) {
      try {
        const spec = app.actions.getProductFromSpec({
          productName: product.name,
        });
        setFreeShip(spec && spec.free_shipping);
      } catch (err) {}
    }
  }, [app.actions, product]);

  if (!product) {
    return null;
  }

  const tagCss = product && getProductTagCss(product.tag);
  const showTag = !freeShipping && product.tag;
  return (
    <Wrapper onClick={onClick} css={extraCss}>
      <Image
        ratio={1}
        background
        src={product.image}
        style={{
          backgroundColor: '#e0e0e0',
          margin: 0,
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      />
      <Content>
        <Text
          style={{
            textAlign: 'center',
            marginBottom: 6,
          }}>
          {product.name}
        </Text>

        {/* price from product model */}
        {product.original_price !== product.price && (
          // original price
          <Text
            color="#aaa"
            style={{
              textDecoration: 'line-through',
              textAlign: 'center',
            }}>
            NT: {product.original_price}
          </Text>
        )}
        {/* price */}
        <Text
          color={config.colors.main}
          style={{
            textAlign: 'center',
            fontWeight: '700',
          }}>
          NT: ${product.price}起
        </Text>
      </Content>

      {showTag && <Tag css={tagCss}>{product.tag}</Tag>}
      {freeShipping && <Tag css={'background-color: #28A689'}>免運</Tag>}
    </Wrapper>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex: 1;
`;

const Tag = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 8px;
  font-size: 12px;
  color: white;
  border-bottom-right-radius: 8px;
  background-color: #eb5554;
  ${(props) => props.css || ''}
`;

const Wrapper = styled.div`
  width: auto;
  margin-bottom: 20px;
  background-color: white;
  /* border: 1px solid #ddd; */
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 3px 10px -3px #6665;
  }
  ${(props) => props.css}
`;

function Text(props) {
  const {color = '', weight = null, inline = false, style} = props;

  return (
    <div
      style={{
        fontSize: config.fontSize.xs,
        color: color || config.colors.text,
        ...(weight && {fontWeight: weight}),
        ...(inline && {display: 'inline'}),
        ...style,
      }}>
      {props.children}
    </div>
  );
}
