import React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';
import {Button} from 'antd';
import Image from '../../../src/Components/Image';
const config = require('../../../src/data.json');

const PORTRAIT_RADIO = 271 / 440;
const LANDSCAPE_RADIO = 120 / 180;

function format(value) {
  return new Date(value).toISOString().slice(0, 16).replace('T', ' ');
}

export default function Item({record, type = 'portrait', extraStyle = {}}) {
  return (
    <Link style={extraStyle} to={`/article?id=${record.id}`}>
      <WrapperPortrait>
        <Image
          src={record.image}
          {...(type === 'portrait'
            ? {
                ratio: PORTRAIT_RADIO,
              }
            : {
                width: 200,
                height: 'auto',
              })}
        />

        <section>
          <h3>{record.title || 'No title'}</h3>
          <div className="abstract">{record.outline || 'No abstract'}</div>

          <div className="misc">
            <div className="date">{format(record.created * 1000)}</div>
            <div style={{flex: 1}} />
            <Button type="link" style={{margin: 0, padding: 0, height: 'auto'}}>
              繼續閱讀
            </Button>
          </div>
        </section>
      </WrapperPortrait>
    </Link>
  );
}

const WrapperPortrait = styled.div`
  border: 1px solid #e0e0e0;
  min-height: 340px;
  display: flex;
  flex-direction: column;

  & > section {
    flex: 1 0 50%;
    padding: 15px;
    display: flex;
    flex-direction: column;

    & > h3 {
      height: 50px;
      padding: 0;
      margin: 0;
      color: ${config.colors.text};
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .abstract {
      height: 70px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: ${config.colors.main};
      margin-bottom: 10px;
    }

    & > .misc {
      display: flex;
      align-items: center;
      border-top: 1px solid #e0e0e0;
      padding-top: 10px;
      color: ${config.colors.main};
    }
  }

  @media only screen and (max-width: ${config.breakpoints.sm}px) {
    margin: 0 0 10px 0;
  }
`;
